import { post } from "./base";

const API_SERVICE_QUERY = "/api/Service/V1/query";

export default {
  getAll: (payload) => {
    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "web_data_select_clasificacionenfermedad",
          type: "string"
        },
        {
          name: "@page",
          value: payload.currentPage,
          type: "int"
        }
      ]
    };

    if (payload.filter !== "all") {
      const { filter, search } = payload;

      request._petitions.push({
        name: "@filtros_AND",
        value: "activo",
        type: "string"
      });

      request._petitions.push({
        name: "@buscar_AND",
        value: `${filter}`,
        type: "string"
      });

      request._petitions.push({
        name: "@filtros_OR",
        value: "denominacion|codigo|tipo|tipoabreviatura",
        type: "string"
      });

      request._petitions.push({
        name: "@buscar_OR",
        value: `%${search}%|%${search}%|%${search}%|%${search}%`,
        type: "string"
      });
    } else if (payload.search) {
      const { search } = payload;

      request._petitions.push({
        name: "@filtros_OR",
        value: "denominacion|codigo|tipo|tipoabreviatura",
        type: "string"
      });

      request._petitions.push({
        name: "@buscar_OR",
        value: `%${search}%|%${search}%|%${search}%|%${search}%`,
        type: "string"
      });
    }
    return post(API_SERVICE_QUERY, request);
  }
};
