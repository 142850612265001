export const columns = [
  {
    title: "N°",
    key: "index",
    width: "4%",
    slots: { customRender: "rangepages" }
  },
  {
    title: "Código",
    dataIndex: "codigo",
    key: "codigo"
  },
  {
    title: "Nombre",
    dataIndex: "denominacion",
    key: "denominacion"
  },
  {
    title: "Tipo",
    dataIndex: "tipo",
    key: "tipo"
  },
  {
    title: "Tipo Abreviatura",
    dataIndex: "tipoabreviatura",
    key: "tipoabreviatura"
  },
  {
    title: "ESTADO",
    dataIndex: "activo",
    key: "activo",
    slots: { customRender: "activo" }
  }
];
